.btn {
  display: inline-block;
  height: 50px;
  padding-left: 36px;
  padding-right: 36px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  color: $inverted-text;
  background: $primary;
  outline: none;

  &:hover {
    color: $inverted-text;
    background: $primary-lighter;
  }

  &:active {
    background: $primary-darker;
  }
}

.mail-link {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  line-height: 22px;
  color: #7f8c8d;

  &:before {
    @include pseudo;
    left: 0;
    top: 7px;
    width: 14px;
    height: 10px;
    background: $mail no-repeat 0 0;
  }
}

.checks__title {
  margin-bottom: 9px;
  font-weight: bold;
}

.checks__list__item {
  position: relative;
  padding-left: 27px;
  font-size: 18px;
  line-height: 36px;

  &:before {
    @include pseudo;
    left: 4px;
    top: 15px;
    width: 14px;
    height: 9px;
    background: $check-product no-repeat 0 0;
    background-size: 100% 100%;
  }
}

@media #{$mobile} {
  .checks__title {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
  }

  .checks__list__item {
    font-size: 16px;
    line-height: 36px;
  }
}

// Section
.section {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $section-padding-mobile;
  padding-right: $section-padding-mobile;
}

@media #{$tablet} {
  .section {
    padding-left: $section-padding-tablet;
    padding-right: $section-padding-tablet;
  }
}

@media #{$desktop} {
  .section {
    padding-left: $section-padding-desktop;
    padding-right: $section-padding-desktop;
  }
}

.section__title {
  font-size: 30px;
  line-height: 42px;
  font-weight: bold;
}

@media #{$mobile} {
  .section__title {
    font-size: 24px;
    line-height: 32px;
  }
}

// Image 100%
.image-full {
  width: 100%;
}

// Sticky btn

.sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 11px $section-padding-mobile;
  border-top: 1px solid $border;
  background: #fff;
}

.sticky__btn {
  width: 100%;
}

@media #{$tablet} {
  .sticky {
    display: none;
  }
}

