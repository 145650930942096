/* Reset
----------------------------------------------------------------------------- */
/* stylelint-disable */
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
address,
code,
img,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
table,
th,
td,
article,
aside,
nav,
section,
figure,
figcaption,
footer,
header,
audio,
video {
  margin: 0;
  padding: 0; }

blockquote,
img,
fieldset,
form {
  border: 0; }

a,
strong,
em,
b,
i,
small,
sub,
sup,
img,
label,
th,
td,
audio,
video {
  vertical-align: baseline; }

applet,
object,
iframe,
abbr,
acronym,
big,
cite,
del,
dfn,
ins,
kbd,
q,
s,
samp,
strike,
tt,
var,
u,
center,
legend,
caption,
tbody,
tfoot,
thead,
tr,
canvas,
details,
embed,
menu,
output,
ruby,
summary,
time,
mark {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit;
  font-size: 100%; }

ul,
ol {
  list-style: none; }

/* Border-box FTW
https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
*,
*:before,
*:after {
  box-sizing: inherit; }

html {
  overflow-y: scroll;
  box-sizing: border-box;
  text-size-adjust: 100%; }

img {
  vertical-align: middle; }

strong,
b {
  font-weight: normal; }

em,
i {
  font-style: italic; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: inherit; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

th {
  font-weight: normal; }

td {
  vertical-align: top; }

input,
select,
textarea,
button {
  margin: 0;
  vertical-align: middle;
  font-size: 100%;
  font-family: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 * 2. Show the overflow in Edge, Firefox, and IE.
 */
button,
input,
select {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  /* 3 */
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

/**
 * 1. Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical; }

svg:not(:root) {
  overflow: hidden;
  /* Correct overflow not hidden in IE. */ }

/**
 * Correct the odd appearance of search inputs in Chrome and Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; }

/**
 * Remove the inner padding and cancel buttons in Chrome on OS X and
 * Safari on OS X.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* stylelint-enable */
.center {
  text-align: center; }

body {
  font-family: soleil, "Arial", "Helvetica", sans-serif;
  font-size: 18px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none;
  color: #000;
  transition: color 0.15s; }
  a:hover {
    color: #3498db; }

.page {
  overflow: hidden;
  min-width: 320px; }

.centered {
  justify-content: space-around; }

.vertical-aligned {
  align-items: center; }

.cols {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -22px; }
  .cols.--nowrap {
    flex-wrap: nowrap; }

.pl-reg {
  padding-left: 22px; }

[class*="col-"], .col {
  padding-right: 22px; }

.col-1 {
  flex: 0 0 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%; }

.col-3 {
  flex: 0 0 25%; }

.col-4 {
  flex: 0 0 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%; }

.col-6 {
  flex: 0 0 50%; }

.col-7 {
  flex: 0 0 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%; }

.col-9 {
  flex: 0 0 75%; }

.col-10 {
  flex: 0 0 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%; }

.col-12 {
  flex: 0 0 100%; }

.ml-1 {
  margin-left: 8.33333%; }

.mr-1 {
  margin-right: 8.33333%; }

.ml-2 {
  margin-left: 16.66667%; }

.mr-2 {
  margin-right: 16.66667%; }

.ml-3 {
  margin-left: 25%; }

.mr-3 {
  margin-right: 25%; }

.ml-4 {
  margin-left: 33.33333%; }

.mr-4 {
  margin-right: 33.33333%; }

.ml-5 {
  margin-left: 41.66667%; }

.mr-5 {
  margin-right: 41.66667%; }

.ml-6 {
  margin-left: 50%; }

.mr-6 {
  margin-right: 50%; }

.ml-7 {
  margin-left: 58.33333%; }

.mr-7 {
  margin-right: 58.33333%; }

.ml-8 {
  margin-left: 66.66667%; }

.mr-8 {
  margin-right: 66.66667%; }

.ml-9 {
  margin-left: 75%; }

.mr-9 {
  margin-right: 75%; }

.ml-10 {
  margin-left: 83.33333%; }

.mr-10 {
  margin-right: 83.33333%; }

.ml-11 {
  margin-left: 91.66667%; }

.mr-11 {
  margin-right: 91.66667%; }

.ml-12 {
  margin-left: 100%; }

.mr-12 {
  margin-right: 100%; }

@media screen and (max-width: 767px) {
  .col-1 {
    flex: 0 0 25%; }
  .col-2 {
    flex: 0 0 50%; }
  .col-3 {
    flex: 0 0 75%; }
  .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    flex: 0 0 100%;
    width: 100%; } }

.btn {
  display: inline-block;
  height: 50px;
  padding-left: 36px;
  padding-right: 36px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background: #3498db;
  outline: none; }
  .btn:hover {
    color: #fff;
    background: #5faee3; }
  .btn:active {
    background: #217dbb; }

.mail-link {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  line-height: 22px;
  color: #7f8c8d; }
  .mail-link:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 7px;
    width: 14px;
    height: 10px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIj4gICAgPHBhdGggZmlsbD0iIzk1QTVBNiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTMuMjMgMEguNzdBLjc3Mi43NzIgMCAwIDAgMCAuNzcxVjkuMjNjMCAuNDI1LjM0Ni43NzEuNzcuNzcxaDEyLjQ2Yy40MjQgMCAuNzctLjM0Ni43Ny0uNzcxVi43N2MuMDE1LS40NC0uMzMtLjc3MS0uNzctLjc3MXptLTEuNDkzIDEuNTQ0bC00LjczIDMuMjktNC44MjMtMy4yOWg5LjU1M3ptLjcyMyA2Ljg5N0gxLjU0di01LjQ4bDUuMDI3IDMuNDMzYS43MzMuNzMzIDAgMCAwIC40NC4xNDIuNzM3LjczNyAwIDAgMCAuNDQtLjE0Mmw1LjAyOS0zLjQ4VjguNDRoLS4wMTZ6Ii8+PC9zdmc+) no-repeat 0 0; }

.checks__title {
  margin-bottom: 9px;
  font-weight: bold; }

.checks__list__item {
  position: relative;
  padding-left: 27px;
  font-size: 18px;
  line-height: 36px; }
  .checks__list__item:before {
    content: '';
    display: block;
    position: absolute;
    left: 4px;
    top: 15px;
    width: 14px;
    height: 9px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE1IDExIj4gICAgPHBhdGggZmlsbD0iIzM0OThEQiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMzNDk4REIiIGQ9Ik02LjA3MSAxMGEuNTU2LjU1NiAwIDAgMS0uMzkyLS4xNjNMMS4xNjMgNS4zMTRhLjU1Ny41NTcgMCAwIDEgLjc4Ni0uNzg3bDQuMTIyIDQuMTMgNy40OC03LjQ5NGEuNTU3LjU1NyAwIDAgMSAuNzg2Ljc4N0w2LjQ2NCA5LjgzN2EuNTUzLjU1MyAwIDAgMS0uMzkzLjE2M3oiLz48L3N2Zz4=) no-repeat 0 0;
    background-size: 100% 100%; }

@media screen and (max-width: 767px) {
  .checks__title {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px; }
  .checks__list__item {
    font-size: 16px;
    line-height: 36px; } }

.section {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px; }

@media screen and (min-width: 768px) {
  .section {
    padding-left: 30px;
    padding-right: 30px; } }

@media screen and (min-width: 1024px) {
  .section {
    padding-left: 43px;
    padding-right: 43px; } }

.section__title {
  font-size: 30px;
  line-height: 42px;
  font-weight: bold; }

@media screen and (max-width: 767px) {
  .section__title {
    font-size: 24px;
    line-height: 32px; } }

.image-full {
  width: 100%; }

.sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 11px 14px;
  border-top: 1px solid #ecf0f1;
  background: #fff; }

.sticky__btn {
  width: 100%; }

@media screen and (min-width: 768px) {
  .sticky {
    display: none; } }

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 42px;
  padding-bottom: 67px; }

.header__logo {
  display: block;
  width: 188px; }
  .header__logo img {
    width: 100%; }

.header__menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 18px; }
  .header__menu.--mobile {
    display: none; }

.header__menu__item {
  margin-left: 60px; }
  .header__menu__item.--active {
    color: #3498db;
    cursor: default; }
  .header__menu__item.--chat {
    position: relative;
    padding-left: 25px; }
    .header__menu__item.--chat:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 9px;
      width: 14px;
      height: 14px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4gICAgPHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMiAwSDIuOEEyLjggMi44IDAgMCAwIDAgMi44VjE0bDQuODI0LTMuNUgxMS4yQTIuOCAyLjggMCAwIDAgMTQgNy43VjIuOEEyLjggMi44IDAgMCAwIDExLjIgMHoiLz48L3N2Zz4=) no-repeat 0 0;
      background-size: cover; }
    .header__menu__item.--chat:hover:before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4gICAgPHBhdGggZmlsbD0iIzM0OThEQiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOC44IDBIMi4yQTIuMiAyLjIgMCAwIDAgMCAyLjJWMTFsMy43OS0yLjc1SDguOGEyLjIgMi4yIDAgMCAwIDIuMi0yLjJWMi4yQTIuMiAyLjIgMCAwIDAgOC44IDB6Ii8+PC9zdmc+); }

@media screen and (max-width: 767px) {
  .header {
    padding-top: 21px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ecf0f1; }
  .header__logo {
    width: 160px; }
  .header__menu {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    padding-right: 20px;
    border-bottom: 1px solid #ecf0f1;
    opacity: 0;
    background: #fff;
    transition: opacity 0.35s; }
    .header__menu.--opened {
      visibility: visible;
      left: -14px;
      right: -14px;
      top: 69px;
      opacity: 1;
      z-index: 11;
      padding: 11px 12px 18px; }
    .header__menu.--desktop {
      display: none; }
    .header__menu.--mobile {
      display: block; }
  .header__menu__item {
    display: block;
    margin-bottom: 20px;
    margin-left: 0;
    padding-left: 14px;
    padding-right: 14px;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px; }
  .header__menu-btn {
    position: relative;
    left: 5px;
    width: 23px;
    height: 23px; }
    .header__menu-btn:before, .header__menu-btn:after, .header__menu-btn__i {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background: #3498db;
      transform: rotate(0deg);
      transition: transform 0.15s; }
    .header__menu-btn:before {
      top: 5px; }
    .header__menu-btn:after {
      bottom: 0; }
    .header__menu-btn__i {
      top: 13px; }
      .--opened .header__menu-btn__i {
        background: transparent; }
    .header__menu-btn.--opened {
      z-index: 12; }
      .header__menu-btn.--opened:before {
        top: 11px;
        transform: rotate(-45deg); }
      .header__menu-btn.--opened:after {
        bottom: 10px;
        transform: rotate(45deg); } }

.footer__i {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #ecf0f1; }

.footer__descr {
  margin-bottom: 1px;
  font-size: 16px;
  line-height: 22px; }

.footer__copy {
  font-size: 14px;
  line-height: 26px;
  color: #95a5a6; }

.footer__link a {
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline; }

.footer__payments {
  margin-top: 16px; }

.footer__text {
  flex: 0 0 310px; }

.footer__title {
  margin-bottom: 2px; }
  .footer__title svg {
    width: 136px;
    height: auto; }

.footer__descr {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 22px; }

.footer__sub-title {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #95a5a6; }

@media screen and (max-width: 1023px) {
  .footer.--with-padding {
    padding-bottom: 30px; }
  .footer__text {
    flex: 0 0 100%;
    margin-bottom: 20px; }
  .footer__descr {
    width: 50%; } }

@media screen and (max-width: 767px) {
  .footer__descr {
    width: 100%; }
  .footer__checkout, .footer__contact, .footer__legal {
    flex: 0 0 100%;
    margin-bottom: 30px; } }

.intro {
  padding-bottom: 100px;
  margin-bottom: 87px; }

.intro__title {
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 64px;
  font-weight: bold; }

.intro__descr {
  margin-bottom: 33px; }

.intro__text {
  padding-right: 60px; }

.intro__image {
  position: relative; }

.intro__image__tag {
  width: 100%; }

.intro__image__arrow {
  position: absolute; }
  .intro__image__arrow.--mobile {
    display: none; }

.intro__btn {
  margin-right: 21px; }

.intro__rating {
  margin: 15px 0;
  color: #7f8c8d;
  font-size: 16px;
  line-height: 20px; }

@media screen and (max-width: 767px) {
  .intro {
    margin-bottom: 40px;
    padding-bottom: 0;
    padding-top: 20px; }
  .intro__title {
    margin-bottom: 11px;
    font-size: 36px;
    line-height: 46px; }
  .intro__descr {
    margin-bottom: 28px; }
  .intro__btn {
    display: none; }
  .intro__image__arrow {
    width: 180px;
    left: 10px;
    bottom: 4px; }
    .intro__image__arrow.--mobile {
      display: block; }
    .intro__image__arrow.--desktop {
      display: none; }
  .intro__image {
    padding-bottom: 60px; } }

@media screen and (min-width: 768px) {
  .intro__image__arrow {
    left: 0; } }

@media screen and (min-width: 1024px) {
  .intro__image__arrow {
    left: 120px;
    bottom: -100px; } }

.features {
  margin-bottom: 143px; }

.features__title {
  margin-bottom: 60px; }

.features__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.features__item {
  width: 33.3%;
  padding: 0 28px;
  text-align: center; }

.features__item__icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 66px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 23px; }

.features__item__title {
  margin-bottom: 9px;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold; }

.features__item__descr {
  font-size: 16px;
  line-height: 22px; }

@media screen and (max-width: 767px) {
  .features {
    margin-bottom: 8px;
    text-align: left; }
  .features__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    text-align: left; }
  .features__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    text-align: left;
    margin-bottom: 25px;
    padding: 0; }
  .features__item__icon {
    height: auto;
    align-items: start;
    justify-content: flex-start;
    flex: 1 0 75px; }
    .features__item__icon.--n1 svg {
      width: 54px;
      height: 34px; }
    .features__item__icon.--n2 svg {
      width: 47px;
      height: 47px; }
    .features__item__icon.--n3 svg {
      width: 53px;
      height: 53px; }
  .features__item__content {
    margin-top: 2px; } }

.stand-out {
  margin-bottom: 110px; }

.stand-out__title {
  margin-bottom: 10px; }

.stand-out__descr {
  margin-bottom: 30px; }

.stand-out__content {
  padding-left: 40px; }

@media screen and (max-width: 767px) {
  .stand-out {
    margin-bottom: 20px; }
  .stand-out__content {
    margin-top: 25px;
    padding-left: 0; }
  .stand-out__content {
    font-size: 16px;
    line-height: 22px; } }

.wellness {
  margin-bottom: 104px; }

.wellness__content {
  padding: 0 90px;
  text-align: center; }

.wellness__icon {
  margin-bottom: 8px; }

.wellness__descr {
  margin-top: 16px;
  font-size: 18px;
  line-height: 26px; }

@media screen and (max-width: 767px) {
  .wellness {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 45px; }
  .wellness__content {
    padding: 0; }
  .wellness__image {
    order: -1;
    padding-right: 0;
    margin-bottom: 30px; }
  .wellness__descr {
    font-size: 16px;
    line-height: 22px; } }

@media screen and (min-width: 768px) {
  .wellness__content {
    padding: 0 40px; } }

@media screen and (min-width: 1024px) {
  .wellness {
    align-items: center; }
  .wellness__content {
    padding-right: 100px; } }

.clients {
  margin-bottom: 76px; }

.clients-list {
  margin-top: 43px;
  align-items: stretch; }
  .clients-list .col-4 {
    display: flex;
    flex-direction: column; }

.clients-list__item {
  height: 100%;
  border: 1px solid #d6dee0;
  border-radius: 5px; }

.clients-list__item__logo {
  height: 40px;
  margin: 36px 30px 17px; }

.clients-list__item__descr {
  padding: 0 30px 40px;
  font-size: 16px;
  line-height: 22px;
  font-style: italic; }

.clients-list__item__person {
  position: relative;
  padding: 0 20px 45px 120px; }

.clients-list__item__person-image {
  position: absolute;
  left: 30px;
  top: -13px;
  width: 72px; }

.clients-list__item__person-name {
  font-size: 16px;
  line-height: 22px; }

.clients-list__item__person-position {
  font-size: 14px;
  line-height: 22px;
  color: #95a5a6; }

@media screen and (max-width: 767px) {
  .clients {
    margin-bottom: 20px; }
  .clients__title {
    text-align: left; }
  .clients-list {
    margin-top: 30px; }
  .clients-list__item {
    margin-bottom: 17px; }
  .clients-list__item__logo {
    margin: 27px 23px 17px; }
  .clients-list__item__descr {
    padding: 0 23px 23px; }
  .clients-list__item__person {
    padding-left: 91px; }
  .clients-list__item__person-image {
    left: 23px;
    top: 3px;
    width: 56px; }
  .clients-list__item__person-position {
    font-size: 14px;
    line-height: 18px; } }

@media screen and (min-width: 768px) {
  .clients-list {
    flex-wrap: nowrap; } }

.coworking__text {
  border-radius: 5px 0 0 5px;
  background: #f7f7f7; }

.coworking__img {
  background: url(images/photos/co-working@2x.jpg) no-repeat 50% 50%;
  background-size: cover;
  border-radius: 0 5px 5px 0; }

.coworking__descr {
  margin-top: 6px;
  margin-bottom: 22px;
  font-size: 18px;
  line-height: 26px; }

.coworking__list-item {
  position: relative;
  margin-bottom: 30px;
  padding-left: 65px; }

.coworking__list-item__icon {
  position: absolute;
  left: 0;
  top: 12px; }
  .coworking__list-item__icon.--n2 {
    left: -3px; }
  .coworking__list-item__icon.--n3 {
    left: -4px;
    top: 8px; }
  .coworking__list-item__icon.--n4 {
    left: 3px;
    top: 9px; }

.coworking__list-item__i {
  flex: 0 0 auto; }

.coworking__list-item__title {
  margin-bottom: 3px;
  font-weight: bold; }

@media screen and (max-width: 767px) {
  .coworking {
    flex-direction: column;
    margin-left: -22px; }
  .coworking__img {
    flex: 1 0 337px;
    order: -1; }
  .coworking__text {
    padding-left: 22px;
    padding-bottom: 10px;
    padding-top: 30px; }
  .coworking__text__i {
    padding: 0; }
  .coworking__list-item {
    padding-left: 73px;
    padding-right: 10px; }
  .coworking__list-item__icon.--n1 {
    left: 3px; }
  .coworking__list-item__icon.--n3 {
    left: 1px; }
  .coworking__list-item__icon.--n4 {
    left: 6px;
    top: -2px; }
  .coworking__list-item__descr {
    font-size: 16px;
    line-height: 22px; } }

@media screen and (min-width: 768px) {
  .coworking__text__i {
    padding: 40px; } }

@media screen and (min-width: 1024px) {
  .coworking {
    padding-right: 22px; }
  .coworking__text__i {
    padding: 60px 72px; } }

.buy-block {
  margin-top: 95px;
  margin-bottom: 110px;
  text-align: center; }

.buy-block__img {
  margin-bottom: 16px; }

.buy-block__title {
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 54px;
  font-weight: bold; }

.buy-block__descr {
  margin-bottom: 25px; }

@media screen and (max-width: 767px) {
  .buy-block {
    margin-top: 40px; }
    .buy-block img {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px; }
  .buy-block__title {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 32px;
    margin-left: 0;
    margin-right: 0; }
  .buy-block__descr {
    font-size: 16px;
    line-height: 22px; } }

@media screen and (min-width: 768px) {
  .buy-block__title {
    margin-left: 15%;
    margin-right: 15%; } }

.product__gallery {
  position: relative;
  width: 58.3333%; }

.gallery__thumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: -2px;
  margin-right: -30px; }

.gallery__thumbs__item {
  width: 81px;
  margin-right: 20px;
  padding: 1px;
  border: 1px solid #fff;
  outline: none; }
  .gallery__thumbs__item img {
    width: 100%; }
  .gallery__thumbs__item.tns-nav-active {
    border-color: #0080ed; }

.gallery__next {
  position: absolute;
  right: 50px;
  top: 50%;
  margin-top: -75px;
  z-index: 10;
  outline: none;
  cursor: pointer; }
  .gallery__next svg {
    vertical-align: middle; }

.gallery__item__img {
  width: 100%; }

@media screen and (max-width: 767px) {
  .product__gallery {
    position: relative;
    top: -1px;
    width: 100%;
    margin-bottom: 33px;
    padding-right: 0; }
  .gallery__next {
    display: none; }
  .gallery__thumbs {
    position: absolute;
    left: 12px;
    bottom: 16px;
    margin: 0; }
  .gallery__thumbs__item {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    padding: 0;
    border-radius: 12px;
    border-color: rgba(0, 0, 0, 0.4); }
    .gallery__thumbs__item.tns-nav-active {
      border-color: rgba(0, 0, 0, 0.4);
      background-color: #0080ed; }
    .gallery__thumbs__item img {
      display: none; } }

.buy {
  flex-wrap: nowrap;
  padding-bottom: 60px; }

.buy__content {
  padding-left: 40px; }

.buy__title {
  margin-bottom: 13px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold; }

.buy__rating-wrapper {
  display: flex;
  /* keeps child element width to it's content, rather 100% */
  margin-bottom: 16px; }

.buy__rating {
  color: #7f8c8d;
  font-size: 14px;
  line-height: 20px; }

.buy__rating-etsy {
  vertical-align: middle; }

.buy__descr {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 26px; }

.buy__btn {
  margin: 30px 0; }

.buy__checks__item {
  position: relative;
  margin-bottom: 12px;
  padding-left: 30px;
  font-size: 16px;
  line-height: 20px; }
  .buy__checks__item:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
    width: 14px;
    height: 9px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE1IDExIj4gICAgPHBhdGggZmlsbD0iIzM0OThEQiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMzNDk4REIiIGQ9Ik02LjA3MSAxMGEuNTU2LjU1NiAwIDAgMS0uMzkyLS4xNjNMMS4xNjMgNS4zMTRhLjU1Ny41NTcgMCAwIDEgLjc4Ni0uNzg3bDQuMTIyIDQuMTMgNy40OC03LjQ5NGEuNTU3LjU1NyAwIDAgMSAuNzg2Ljc4N0w2LjQ2NCA5LjgzN2EuNTUzLjU1MyAwIDAgMS0uMzkzLjE2M3oiLz48L3N2Zz4=);
    background-size: 100% 100%; }

.buy__shipping {
  margin-top: 21px;
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 26px; }

.buy__shipping__icon {
  width: 18px;
  margin-right: 9px; }

.buy__contact {
  font-size: 14px;
  line-height: 18px;
  color: #7f8c8d; }
  .buy__contact a {
    color: #7f8c8d;
    text-decoration: underline; }

@media screen and (max-width: 767px) {
  .buy {
    display: block;
    padding-bottom: 27px;
    margin-left: -14px;
    margin-bottom: 35px; }
  .buy__content {
    padding-left: 14px; }
  .buy__title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold; } }

.sub-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold; }

.number-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 18px; }

.number-list__item__number {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 14px;
  padding-top: 4px;
  border: 1px solid #909090;
  border-radius: 30px;
  text-align: center; }

.delivery {
  margin-bottom: 13px;
  font-size: 22px;
  line-height: 22px; }
  .delivery svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
    vertical-align: middle; }

.deadline__title {
  font-size: 14px;
  line-height: 22px;
  font-weight: bold; }

.deadline__descr {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d; }

.specs {
  margin-top: 40px;
  margin-bottom: 40px; }

.specs__image-mob {
  display: none; }

.specs__image-desk {
  width: 100%;
  padding-right: 8.333%; }

.pricing-col {
  padding-right: 6%; }

.pricing {
  width: 100%; }
  .pricing th, .pricing td {
    width: 33.3%;
    padding: 4px 15px;
    font-size: 16px;
    line-height: 22px;
    text-align: left; }
  .pricing th {
    font-weight: bold;
    padding-bottom: 10px; }
  .pricing td:first-child {
    border-radius: 5px 0 0 5px; }
  .pricing td:last-child {
    border-radius: 0 5px 5px 0; }
  .pricing tr:nth-child(even) {
    background: #f3f6f7; }
  .pricing tr:nth-child(odd) td {
    padding-top: 12px;
    padding-bottom: 12px; }

.pricing__contact {
  line-height: 20px;
  text-decoration: underline; }

.plain-text-group {
  margin-bottom: 26px; }

.plain-text {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px; }
  .plain-text strong {
    font-weight: bold; }
  .plain-text a {
    text-decoration: underline; }

.founder {
  padding-top: 100px;
  padding-bottom: 95px;
  margin-bottom: 15px;
  background: #f7f7f7;
  border-radius: 5px;
  text-align: center; }

.founder__title {
  margin-top: 20px;
  margin-bottom: 18px; }

.founder__btn {
  display: inline-block;
  width: 180px;
  height: 43px;
  border: 1px solid #3498db;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 43px;
  text-align: center;
  color: #3498db; }
  .founder__btn:hover {
    border-color: #217dbb; }

.founder__btn__icon {
  position: relative;
  top: 2px;
  margin-right: 10px;
  width: 11px; }

.founder-link {
  text-align: center; }

.health {
  margin-bottom: 40px;
  margin-right: 0; }

.health__img svg {
  width: 100%; }

.local {
  margin-bottom: 35px;
  align-items: center; }

.local__img, .health__img {
  flex: 0 0 37%; }

@media screen and (max-width: 767px) {
  .pricing-col {
    order: -1; }
  .pricing {
    width: 100%;
    margin-bottom: 40px; }
  .number-list {
    margin-bottom: 34px; }
  .specs {
    margin: 30px 0; }
  .specs-pricing {
    flex-direction: column; }
  .specs__image-desk {
    display: none; }
  .specs__image-mob {
    display: block;
    margin-bottom: 22px; }
  .local {
    display: block; }
  .local__img {
    margin-bottom: 22px; }
  .local__text {
    margin-bottom: 35px;
    padding-left: 0; }
  .health {
    display: block;
    width: 100%;
    margin-bottom: 25px; }
  .health__img {
    margin-bottom: 25px; }
  .health__text {
    padding-left: 0; }
  .founder {
    padding-top: 46px;
    padding-bottom: 53px; }
  .founder-link {
    margin-bottom: 35px; } }

.stand {
  margin-bottom: 60px;
  padding-left: 50px; }

.stand__title {
  margin-bottom: 18px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold; }

.stand__descr {
  margin-bottom: 23px;
  font-size: 18px;
  line-height: 26px; }

.stand__author {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: nowrap; }

.stand__author__img {
  height: 62px;
  flex: 1 0 62px; }

.stand__author__text {
  margin-bottom: 28px;
  padding-left: 20px;
  font-size: 16px;
  line-height: 24px;
  font-style: italic; }

.stand__personal {
  position: relative;
  margin-bottom: 25px;
  padding-left: 40px; }

.stand__personal__title {
  margin-bottom: 5px; }

.stand__personal__descr {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d; }

.stand__personal__icon {
  position: absolute;
  left: 0;
  top: 3px; }

.stand__btn {
  width: 100%;
  margin-bottom: 50px; }

.stand__p {
  margin-bottom: 30px; }

.stand__sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold; }

.stand__materials {
  margin-top: 35px;
  margin-bottom: 45px; }

.stand__materials__item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  align-items: center; }

.stand__materials__item-img {
  flex: 0 0 92px;
  margin-right: 25px; }
  .stand__materials__item-img img {
    width: 100%; }

.stand__materials__item-title {
  margin-bottom: 2px;
  font-weight: bold; }

.stand__materials__item-params {
  margin-bottom: 3px; }

.stand__materials__item-info {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d; }

.stand__materials__item-descr {
  margin-top: 3px; }

.stand__wood__title {
  margin-bottom: 10px;
  font-weight: bold; }

.stand__wood__p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px; }

.stand__wood__img {
  margin-top: 22px;
  margin-bottom: 22px; }

.images-list {
  margin-bottom: 60px; }

.images-list__item {
  margin-bottom: 20px; }
  .images-list__item img {
    border-radius: 10px; }

@media screen and (max-width: 767px) {
  .diy-page {
    display: block; }
  .images-list-gallery {
    position: relative;
    width: auto;
    margin-left: -14px;
    margin-bottom: 33px;
    padding-right: 0; }
  .images-list {
    margin-bottom: 0; }
  .images-list__item {
    margin-bottom: 0; }
    .images-list__item img {
      border-radius: 0; }
  .stand {
    margin-bottom: 30px;
    padding-left: 0; }
  .stand__title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 32px; }
  .stand__author {
    width: 100%; }
  .stand__btn {
    display: none; }
  .stand__materials__item {
    align-items: start; }
  .stand__materials__item-img {
    flex: 0 0 57px;
    margin-right: 20px; }
  .stand__materials__item-params {
    font-size: 16px;
    line-height: 22px; }
  .stand__materials__item-descr {
    margin-top: -1px; }
  .stand__personal {
    margin-bottom: 20px; }
  .stand__author__text {
    margin-top: -6px;
    line-height: 22px; }
  .stand__materials {
    margin-top: 25px; }
  .stand__p {
    font-size: 16px;
    line-height: 22px; }
  .stand__sub-title {
    margin-bottom: 4px; }
  .stand__wood__title {
    margin-bottom: 7px; }
  .stand__wood__img {
    margin-bottom: 13px; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .stand {
    padding-left: 20px;
    flex: 0 0 50%; }
  .images-list-gallery {
    flex: 0 0 50%; } }

@media screen and (min-width: 1024px) {
  .images-list-gallery {
    flex-shrink: 1; } }

.diy__intro {
  width: 650px;
  margin: 0 auto 55px;
  text-align: center; }

.diy__title {
  margin-bottom: 18px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold; }

.diy__descr {
  margin-bottom: 23px;
  font-size: 18px;
  line-height: 26px; }

.diy__non-commercial {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  font-size: 16px;
  line-height: 20px;
  color: #7f8c8d; }
  .diy__non-commercial svg {
    margin-right: 10px; }

.diy__sections {
  display: flex;
  margin-right: -22px;
  margin-bottom: 70px; }

.diy__section {
  flex: 1 0 50%;
  padding-right: 22px; }

.diy__section__i {
  padding: 0 40px 68px;
  border-radius: 10px;
  text-align: center;
  background: #f7f7f7;
  cursor: pointer; }
  .diy__section__i:hover {
    box-shadow: inset 0 0 0 2px #3498db;
    color: #000; }

.diy__section__title {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: #000; }

.diy__section__descr {
  font-size: 16px;
  line-height: 22px;
  color: #7f8c8d; }

.diy__section__icon {
  height: 310px; }
  .diy__section__icon.--n1 {
    padding-top: 84px; }
  .diy__section__icon.--n2 {
    padding-top: 51px; }

@media screen and (max-width: 767px) {
  .diy__intro {
    padding-top: 40px;
    width: 100%; }
  .diy__sections {
    flex-wrap: wrap; }
  .diy__section {
    flex: 1 0 100%;
    margin-bottom: 20px; }
  .diy__section__i {
    padding: 0 20px 40px; }
  .diy__section__icon.--n1 {
    padding-top: 50px; }
  .diy__section__icon.--n2 {
    padding-top: 50px; } }

.faq {
  margin-bottom: 35px;
  padding-top: 27px;
  padding-bottom: 28px;
  border-top: 1px solid #d4dfe0;
  border-bottom: 1px solid #d4dfe0; }

.faq__item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  flex: 0 0 33%;
  padding-right: 80px; }

.faq__item__icon.--n1 {
  flex: 0 0 51px; }

.faq__item__icon.--n2 {
  flex: 0 0 64px; }

.faq__item__icon.--n3 {
  flex: 0 0 67px; }

.faq__item__title {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase; }

.faq__item__descr {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d; }

@media screen and (max-width: 767px) {
  .faq {
    padding-bottom: 0; }
  .faq__item {
    align-items: center;
    margin-bottom: 34px;
    flex: 0 0 100%;
    padding-right: 24px; }
  .faq__item__icon {
    text-align: center; }
    .faq__item__icon.--n1, .faq__item__icon.--n2, .faq__item__icon.--n3 {
      flex: 0 0 73px; } }

.rating {
  display: flex;
  align-items: center; }

.rating__stars {
  flex: none; }

.rating__text {
  margin-left: 9px; }

.terms {
  padding: 23px 0 40px; }

.terms__title {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px; }

.terms__content {
  max-width: 700px; }
  .terms__content h3 {
    font-size: 30px;
    line-height: 38px; }
  .terms__content h2, .terms__content h3, .terms__content h4, .terms__content h5 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold; }
  .terms__content b, .terms__content strong {
    font-weight: bold; }
  .terms__content p {
    margin-bottom: 10px; }
  .terms__content ul {
    margin-bottom: 10px; }
  .terms__content li {
    position: relative;
    padding-left: 27px; }
    .terms__content li:before {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      top: 11px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: #000; }

@media screen and (min-width: 768px) {
  .terms__title {
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 48px; }
  .terms__content {
    flex: 0 0 66.66667%;
    font-size: 18px;
    line-height: 26px; } }

@media screen and (min-width: 1024px) {
  .terms__title {
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 38px; }
  .terms__i {
    justify-content: space-around; }
  .terms__content {
    flex: 0 0 50%;
    font-size: 18px;
    line-height: 26px; }
    .terms__content ul {
      margin: 25px 0; } }

/* purgecss start ignore */
.tns-outer {
  padding: 0 !important; }

.tns-outer [hidden] {
  display: none !important; }

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer; }

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s; }

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s; }

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important; }

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6; }

.tns-lazy-img.tns-complete {
  opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both; }

.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left; }

/* purgecss end ignore */
/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */
.about__intro-image {
  margin-bottom: 70px; }

.about__intro-title {
  margin-bottom: 65px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  text-align: center; }

.about__cols {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: nowrap; }

.about__founder {
  flex: 0 0 177px;
  margin-right: 27px;
  text-align: center; }

.about__founder__img {
  margin-bottom: 23px; }

.about__content {
  flex: 0 1 590px;
  margin-bottom: 80px; }

.about__sub-title {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold; }

.about__city-title {
  margin-top: 36px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold; }

.about__text p {
  margin-bottom: 14px; }

.about__city__image {
  margin-bottom: 20px; }

.about__city__text {
  margin-bottom: 16px; }

.about__certificates {
  margin-bottom: 45px; }
  .about__certificates img {
    padding-right: 34px; }

@media screen and (min-width: 1024px) {
  .about__cols {
    margin-left: 16.6%; } }

@media screen and (max-width: 767px) {
  .about__cols {
    display: block; }
  .about__founder {
    margin-right: 0;
    margin-bottom: 40px; }
  .about__certificates img {
    padding-right: 10px;
    margin-bottom: 10px; } }
