@mixin row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin cols {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -#{$column-padding};
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin pseudo {
  content: '';
  display: block;
  position: absolute;
}

@mixin col {
  padding-right: $column-padding;
}

@mixin text-bigger {
  font-size: 18px;
  line-height: 26px;
}
