// Intro

.intro {
  padding-bottom: 100px;
  margin-bottom: 87px;
}

.intro__title {
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 64px;
  font-weight: bold;
}

.intro__descr {
  margin-bottom: 33px;
}

.intro__text {
  padding-right: 60px;
}

.intro__image {
  position: relative;
}

.intro__image__tag {
  width: 100%;
}

.intro__image__arrow {
  position: absolute;

  &.--mobile {
    display: none;
  }
}

.intro__btn {
  margin-right: 21px;
}

.intro__rating {
  margin: 15px 0;
  color: #7f8c8d;
  font-size: 16px;
  line-height: 20px;
}

@media #{$mobile} {
  .intro {
    margin-bottom: 40px;
    padding-bottom: 0;
    padding-top: 20px;
  }

  .intro__title {
    margin-bottom: 11px;
    font-size: 36px;
    line-height: 46px;
  }

  .intro__descr {
    margin-bottom: 28px;
  }

  .intro__btn {
    display: none;
  }

  .intro__image__arrow {
    width: 180px;
    left: 10px;
    bottom: 4px;

    &.--mobile {
      display: block;
    }

    &.--desktop {
      display: none;
    }
  }

  .intro__image {
    padding-bottom: 60px;
  }
}

@media #{$tablet} {
  .intro__image__arrow {
    left: 0;
  }
}

@media #{$desktop} {
  .intro__image__arrow {
    left: 120px;
    bottom: -100px;
  }
}

// Features

.features {
  margin-bottom: 143px;
}

.features__title {
  margin-bottom: 60px;
}

.features__list {
  @include row;
}

.features__item {
  width: 33.3%;
  padding: 0 28px;
  text-align: center;
}

.features__item__icon {
  @include row;
  width: 100%;
  height: 66px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 23px;
}

.features__item__title {
  margin-bottom: 9px;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

.features__item__descr {
  font-size: 16px;
  line-height: 22px;
}

@media #{$mobile} {
  .features {
    margin-bottom: 8px;
    text-align: left;
  }

  .features__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }

  .features__item {
    width: 100%;
    @include row;
    flex-wrap: nowrap;
    text-align: left;
    margin-bottom: 25px;
    padding: 0;
  }

  .features__item__icon {
    height: auto;
    align-items: start;
    justify-content: flex-start;
    flex: 1 0 75px;

    &.--n1 {
      svg {
        width: 54px;
        height: 34px;
      }
    }

    &.--n2 {
      svg {
        width: 47px;
        height: 47px;
      }
    }

    &.--n3 {
      svg {
        width: 53px;
        height: 53px;
      }
    }
  }

  .features__item__content {
    margin-top: 2px;
  }
}

// Stand out

.stand-out {
  margin-bottom: 110px;
}

.stand-out__title {
  margin-bottom: 10px;
}

.stand-out__descr {
  margin-bottom: 30px;
}

.stand-out__content {
  padding-left: 40px;
}

@media #{$mobile} {
  .stand-out {
    margin-bottom: 20px;
  }

  .stand-out__content {
    margin-top: 25px;
    padding-left: 0;
  }

  .stand-out__content {
    font-size: 16px;
    line-height: 22px;
  }
}

// Wellness

.wellness {
  margin-bottom: 104px;
}

.wellness__content {
  padding: 0 90px;
  text-align: center;
}

.wellness__icon {
  margin-bottom: 8px;
}

.wellness__descr {
  margin-top: 16px;
  @include text-bigger;
}

@media #{$mobile} {
  .wellness {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 45px;
  }

  .wellness__content {
    padding: 0;
  }

  .wellness__image {
    order: -1;
    padding-right: 0;
    margin-bottom: 30px;
  }

  .wellness__descr {
    font-size: 16px;
    line-height: 22px;
  }
}

@media #{$tablet} {
  .wellness__content {
    padding: 0 40px;
  }
}

@media #{$desktop} {
  .wellness {
    align-items: center;
  }

  .wellness__content {
    padding-right: 100px;
  }
}

// Clients

.clients {
  margin-bottom: 76px;
}

.clients-list {
  margin-top: 43px;
  align-items: stretch;

  .col-4 {
    display: flex;
    flex-direction: column;
  }
}

.clients-list__item {
  height: 100%;
  border: 1px solid #d6dee0;
  border-radius: 5px;
}

.clients-list__item__logo {
  height: 40px;
  margin: 36px 30px 17px;
}

.clients-list__item__descr {
  padding: 0 30px 40px;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
}

.clients-list__item__person {
  position: relative;
  padding: 0 20px 45px 120px;
}

.clients-list__item__person-image {
  position: absolute;
  left: 30px;
  top: -13px;
  width: 72px;
}

.clients-list__item__person-name {
  font-size: 16px;
  line-height: 22px;
}

.clients-list__item__person-position {
  font-size: 14px;
  line-height: 22px;
  color: #95a5a6;
}

@media #{$mobile} {
  .clients {
    margin-bottom: 20px;
  }

  .clients__title {
    text-align: left;
  }

  .clients-list {
    margin-top: 30px;
  }

  .clients-list__item {
    margin-bottom: 17px;
  }

  .clients-list__item__logo {
    margin: 27px 23px 17px;
  }

  .clients-list__item__descr {
    padding: 0 23px 23px;
  }

  .clients-list__item__person {
    padding-left: 91px;
  }

  .clients-list__item__person-image {
    left: 23px;
    top: 3px;
    width: 56px;
  }

  .clients-list__item__person-position {
    font-size: 14px;
    line-height: 18px;
  }
}

@media #{$tablet} {
  .clients-list {
    flex-wrap: nowrap;
  }
}

// Coworking

.coworking__text {
  border-radius: 5px 0 0 5px;
  background: #f7f7f7;
}

.coworking__img {
  background: url(images/photos/co-working@2x.jpg) no-repeat 50% 50%;
  background-size: cover;
  border-radius: 0 5px 5px 0;
}

.coworking__descr {
  margin-top: 6px;
  margin-bottom: 22px;
  font-size: 18px;
  line-height: 26px;
}

.coworking__list-item {
  position: relative;
  margin-bottom: 30px;
  padding-left: 65px;
}

.coworking__list-item__icon {
  position: absolute;
  left: 0;
  top: 12px;

  &.--n2 {
    left: -3px;
  }

  &.--n3 {
    left: -4px;
    top: 8px;
  }

  &.--n4 {
    left: 3px;
    top: 9px;
  }
}

.coworking__list-item__i {
  flex: 0 0 auto;
}

.coworking__list-item__title {
  margin-bottom: 3px;
  font-weight: bold;
}

@media #{$mobile} {
  .coworking {
    flex-direction: column;
    margin-left: -22px;
  }

  .coworking__img {
    flex: 1 0 337px;
    order: -1;
  }

  .coworking__text {
    padding-left: 22px;
    padding-bottom: 10px;
    padding-top: 30px;
  }

  .coworking__text__i {
    padding: 0;
  }

  .coworking__list-item {
    padding-left: 73px;
    padding-right: 10px;
  }

  .coworking__list-item__icon {
    &.--n1 {
      left: 3px;
    }

    &.--n3 {
      left: 1px;
    }

    &.--n4 {
      left: 6px;
      top: -2px;
    }
  }

  .coworking__list-item__descr {
    font-size: 16px;
    line-height: 22px;
  }
}

@media #{$tablet} {
  .coworking__text__i {
    padding: 40px;
  }
}

@media #{$desktop} {
  .coworking {
    padding-right: 22px;
  }

  .coworking__text__i {
    padding: 60px 72px;
  }
}

// Buy block

.buy-block {
  margin-top: 95px;
  margin-bottom: 110px;
  text-align: center;
}

.buy-block__img {
  margin-bottom: 16px;
}

.buy-block__title {
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 54px;
  font-weight: bold;
}

.buy-block__descr {
  margin-bottom: 25px;
}

@media #{$mobile} {
  .buy-block {
    margin-top: 40px;

    img {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .buy-block__title {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 32px;
    margin-left: 0;
    margin-right: 0;
  }

  .buy-block__descr {
    font-size: 16px;
    line-height: 22px;
  }
}

@media #{$tablet} {
  .buy-block__title {
    margin-left: 15%;
    margin-right: 15%;
  }
}

// Gallery
.product__gallery {
  position: relative;
  width: 58.3333%;
}

.gallery__thumbs {
  @include row;
  margin-top: 20px;
  margin-left: -2px;
  margin-right: -30px;
}

.gallery__thumbs__item {
  width: 81px;
  margin-right: 20px;
  padding: 1px;
  border: 1px solid #fff;
  outline: none;

  img {
    width: 100%;
  }

  &.tns-nav-active {
    border-color: #0080ed;
  }
}

.gallery__next {
  position: absolute;
  right: 50px;
  top: 50%;
  margin-top: -75px;
  z-index: 10;
  outline: none;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }
}

.gallery__item__img {
  width: 100%;
}

@media #{$mobile} {
  .product__gallery {
    position: relative;
    top: -1px;
    width: 100%;
    margin-bottom: 33px;
    padding-right: 0;
  }

  .gallery__next {
    display: none;
  }

  .gallery__thumbs {
    position: absolute;
    left: 12px;
    bottom: 16px;
    margin: 0;
  }

  .gallery__thumbs__item {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    padding: 0;
    border-radius: 12px;
    border-color: rgba(0, 0, 0, .4);

    &.tns-nav-active {
      border-color: rgba(0, 0, 0, .4);
      background-color: #0080ed;
    }

    img {
      display: none;
    }
  }
}

// Product

.buy {
  flex-wrap: nowrap;
  padding-bottom: 60px;
}

.buy__content {
  padding-left: 40px;
}

.buy__title {
  margin-bottom: 13px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
}

.buy__rating-wrapper {
  display: flex; /* keeps child element width to it's content, rather 100% */
  margin-bottom: 16px;
}

.buy__rating {
  color: #7f8c8d;
  font-size: 14px;
  line-height: 20px;
}

.buy__rating-etsy {
  vertical-align: middle;
}

.buy__descr {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 26px;
}

.buy__btn {
  margin: 30px 0;
}

.buy__checks__item {
  position: relative;
  margin-bottom: 12px;
  padding-left: 30px;
  font-size: 16px;
  line-height: 20px;

  &:before {
    @include pseudo;
    left: 0;
    top: 5px;
    width: 14px;
    height: 9px;
    background: $check-product;
    background-size: 100% 100%;
  }
}

.buy__shipping {
  margin-top: 21px;
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 26px;
}

.buy__shipping__icon {
  width: 18px;
  margin-right: 9px;
}

.buy__contact {
  font-size: 14px;
  line-height: 18px;
  color: #7f8c8d;

  a {
    color: #7f8c8d;
    text-decoration: underline;
  }
}

@media #{$mobile} {
  .buy {
    display: block;
    padding-bottom: 27px;
    margin-left: -$section-padding-mobile;
    margin-bottom: 35px;
  }

  .buy__content {
    padding-left: $section-padding-mobile;
  }

  .buy__title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
  }
}

// Details on Product page

.sub-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
}

.number-list__item {
  @include row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 18px;
}

.number-list__item__number {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 14px;
  padding-top: 4px;
  border: 1px solid #909090;
  border-radius: 30px;
  text-align: center;
}

.delivery {
  margin-bottom: 13px;
  font-size: 22px;
  line-height: 22px;

  svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
    vertical-align: middle;
  }
}

.deadline__title {
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}

.deadline__descr {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d;
}

.specs {
  margin-top: 40px;
  margin-bottom: 40px;
}

.specs__image-mob {
  display: none;
}

.specs__image-desk {
  width: 100%;
  padding-right: 8.333%;
}

.pricing-col {
  padding-right: 6%;
}

.pricing {
  width: 100%;

  th, td {
    width: 33.3%;
    padding: 4px 15px;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }

  th {
    font-weight: bold;
    padding-bottom: 10px;
  }

  td {
    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  tr {
    &:nth-child(even) {
      background: #f3f6f7;
    }

    &:nth-child(odd) {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
}

.pricing__contact {
  line-height: 20px;
  text-decoration: underline;
}

.plain-text-group {
  margin-bottom: 26px;
}

.plain-text {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;

  strong {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }
}

.founder {
  padding-top: 100px;
  padding-bottom: 95px;
  margin-bottom: 15px;
  background: #f7f7f7;
  border-radius: 5px;
  text-align: center;
}

.founder__title {
  margin-top: 20px;
  margin-bottom: 18px;
}

.founder__btn {
  display: inline-block;
  width: 180px;
  height: 43px;
  border: 1px solid $primary;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 43px;
  text-align: center;
  color: $primary;

  &:hover {
    border-color: $primary-darker;
  }
}

.founder__btn__icon {
  position: relative;
  top: 2px;
  margin-right: 10px;
  width: 11px;
}

.founder-link {
  text-align: center;
}

.health {
  margin-bottom: 40px;
  margin-right: 0;
}

.health__img {
  svg {
    width: 100%;
  }
}

.local {
  margin-bottom: 35px;
  align-items: center;
}

.local__img, .health__img {
  flex: 0 0 37%;
}

@media #{$mobile} {
  .pricing-col {
    order: -1
  }

  .pricing {
    width: 100%;
    margin-bottom: 40px;
  }

  .number-list {
    margin-bottom: 34px;
  }

  .specs {
    margin: 30px 0;
  }

  .specs-pricing {
    flex-direction: column;
  }

  .specs__image-desk {
    display: none;
  }

  .specs__image-mob {
    display: block;
    margin-bottom: 22px;
  }

  .local {
    display: block;
  }

  .local__img {
    margin-bottom: 22px;
  }

  .local__text {
    margin-bottom: 35px;
    padding-left: 0;
  }

  .health {
    display: block;
    width: 100%;
    margin-bottom: 25px;
  }

  .health__img {
    margin-bottom: 25px;
  }

  .health__text {
    padding-left: 0;
  }

  .founder {
    padding-top: 46px;
    padding-bottom: 53px;
  }

  .founder-link {
    margin-bottom: 35px;
  }
}

// Stand DIY

.stand {
  margin-bottom: 60px;
  padding-left: 50px;
}

.stand__title {
  margin-bottom: 18px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
}

.stand__descr {
  margin-bottom: 23px;
  font-size: 18px;
  line-height: 26px;
}

.stand__author {
  @include row;
  flex-wrap: nowrap;
}

.stand__author__img {
  height: 62px;
  flex: 1 0 62px;
}

.stand__author__text {
  margin-bottom: 28px;
  padding-left: 20px;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
}

.stand__personal {
  position: relative;
  margin-bottom: 25px;
  padding-left: 40px;
}

.stand__personal__title {
  margin-bottom: 5px;
}

.stand__personal__descr {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d;
}

.stand__personal__icon {
  position: absolute;
  left: 0;
  top: 3px;
}

.stand__btn {
  width: 100%;
  margin-bottom: 50px;
}

.stand__p {
  margin-bottom: 30px;
}

.stand__sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}

.stand__materials {
  margin-top: 35px;
  margin-bottom: 45px;
}

.stand__materials__item {
  @include row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  align-items: center;
}

.stand__materials__item-img {
  flex: 0 0 92px;
  margin-right: 25px;

  img {
    width: 100%;
  }
}

.stand__materials__item-title {
  margin-bottom: 2px;
  font-weight: bold;
}

.stand__materials__item-params {
  margin-bottom: 3px;
}

.stand__materials__item-info {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d;
}

.stand__materials__item-descr {
  margin-top: 3px;
}

.stand__wood__title {
  margin-bottom: 10px;
  font-weight: bold;
}

.stand__wood__p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}

.stand__wood__img {
  margin-top: 22px;
  margin-bottom: 22px;
}

.images-list {
  margin-bottom: 60px;
}

.images-list__item {
  margin-bottom: 20px;

  img {
    border-radius: 10px;
  }
}

@media #{$mobile} {
  .diy-page {
    display: block;
  }

  .images-list-gallery {
    position: relative;
    width: auto;
    margin-left: -$section-padding-mobile;
    margin-bottom: 33px;
    padding-right: 0;
  }

  .images-list {
    margin-bottom: 0;
  }

  .images-list__item {
    margin-bottom: 0;

    img {
      border-radius: 0;
    }
  }

  .stand {
    margin-bottom: 30px;
    padding-left: 0;
  }

  .stand__title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 32px;
  }

  .stand__author {
    width: 100%;
  }

  .stand__btn {
    display: none;
  }

  .stand__materials__item {
    align-items: start;
  }

  .stand__materials__item-img {
    flex: 0 0 57px;
    margin-right: 20px;
  }

  .stand__materials__item-params {
    font-size: 16px;
    line-height: 22px;
  }

  .stand__materials__item-descr {
    margin-top: -1px;
  }

  .stand__personal {
    margin-bottom: 20px;
  }

  .stand__author__text {
    margin-top: -6px;
    line-height: 22px;
  }

  .stand__materials {
    margin-top: 25px;
  }

  .stand__p {
    font-size: 16px;
    line-height: 22px;
  }

  .stand__sub-title {
    margin-bottom: 4px;
  }

  .stand__wood__title {
    margin-bottom: 7px;
  }

  .stand__wood__img {
    margin-bottom: 13px;
  }
}

@media #{$tablet-only} {
  .stand {
    padding-left: 20px;
    flex: 0 0 50%;
  }

  .images-list-gallery {
    flex: 0 0 50%;
  }
}

@media #{$desktop} {
  .images-list-gallery {
    flex-shrink: 1;
  }
}

// DIY

.diy__intro {
  width: 650px;
  margin: 0 auto 55px;
  text-align: center;
}

.diy__title {
  margin-bottom: 18px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
}

.diy__descr {
  margin-bottom: 23px;
  font-size: 18px;
  line-height: 26px;
}

.diy__non-commercial {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  font-size: 16px;
  line-height: 20px;
  color: #7f8c8d;

  svg {
    margin-right: 10px;
  }
}

.diy__sections {
  display: flex;
  margin-right: -22px;
  margin-bottom: 70px;
}

.diy__section {
  flex: 1 0 50%;
  padding-right: 22px;
}

.diy__section__i {
  padding: 0 40px 68px;
  border-radius: 10px;
  text-align: center;
  background: #f7f7f7;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 0 2px $primary;
    color: $text;
  }
}

.diy__section__title {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: $text;
}

.diy__section__descr {
  font-size: 16px;
  line-height: 22px;
  color: #7f8c8d;
}

.diy__section__icon {
  height: 310px;

  &.--n1 {
    padding-top: 84px;
  }

  &.--n2 {
    padding-top: 51px;
  }
}

@media #{$mobile} {
  .diy__intro {
    padding-top: 40px;
    width: 100%;
  }

  .diy__sections {
    flex-wrap: wrap;
  }

  .diy__section {
    flex: 1 0 100%;
    margin-bottom: 20px;
  }

  .diy__section__i {
    padding: 0 20px 40px;
  }

  .diy__section__icon {
    &.--n1 {
      padding-top: 50px;
    }

    &.--n2 {
      padding-top: 50px;
    }
  }
}

// FAQ

.faq {
  margin-bottom: 35px;
  padding-top: 27px;
  padding-bottom: 28px;
  border-top: 1px solid #d4dfe0;
  border-bottom: 1px solid #d4dfe0;
}

.faq__item {
  @include row;
  flex-wrap: nowrap;
  flex: 0 0 33%;
  padding-right: 80px;
}

.faq__item__icon {
  &.--n1 {
    flex: 0 0 51px;
  }
  &.--n2 {
    flex: 0 0 64px;
  }
  &.--n3 {
    flex: 0 0 67px;
  }
}

.faq__item__title {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.faq__item__descr {
  font-size: 14px;
  line-height: 20px;
  color: #7f8c8d;
}

@media #{$mobile} {
  .faq {
    padding-bottom: 0;
  }

  .faq__item {
    align-items: center;
    margin-bottom: 34px;
    flex: 0 0 100%;
    padding-right: 24px;
  }

  .faq__item__icon {
    text-align: center;

    &.--n1, &.--n2, &.--n3 {
      flex: 0 0 73px;
    }
  }
}

.rating {
  display: flex;
  align-items: center;
}

.rating__stars {
  flex: none;
}

.rating__text {
  margin-left: 9px;
}
