body {
  font-family: $font-family;
  font-size: 18px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: $text;
  transition: color $short-transition;

  &:hover {
    color: $primary;
  }
}

