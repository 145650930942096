.about__intro-image {
  margin-bottom: 70px;
}

.about__intro-title {
  margin-bottom: 65px;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  text-align: center;
}

.about__cols {
  @include row;
  flex-wrap: nowrap;
}

.about__founder {
  flex: 0 0 177px;
  margin-right: 27px;
  text-align: center;
}

.about__founder__img {
  margin-bottom: 23px;
}

.about__content {
  flex: 0 1 590px;
  margin-bottom: 80px;
}

.about__sub-title {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}

.about__city-title {
  margin-top: 36px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}

.about__text {
  p {
    margin-bottom: 14px;
  }
}

.about__city__image {
  margin-bottom: 20px;
}

.about__city__text {
  margin-bottom: 16px;
}

.about__certificates {
  margin-bottom: 45px;

  img {
    padding-right: 34px;
  }
}

@media #{$desktop} {
  .about__cols {
    margin-left: 16.6%;
  }
}

@media #{$mobile} {
  .about__cols {
    display: block;
  }

  .about__founder {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .about__certificates {
    img {
      padding-right: 10px;
      margin-bottom: 10px;
    }
  }
}
