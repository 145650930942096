.header {
  @include row;
  justify-content: space-between;
  align-items: center;
  padding-top: 42px;
  padding-bottom: 67px;
}

.header__logo {
  display: block;
  width: 188px;

  img {
    width: 100%;
  }
}

.header__menu {
  @include row;
  align-items: center;
  padding-right: 18px;

  &.--mobile {
    display: none;
  }
}

.header__menu__item {
  margin-left: 60px;

  &.--active {
    color: $primary;
    cursor: default;
  }

  &.--chat {
    position: relative;
    padding-left: 25px;

    &:before {
      @include pseudo;
      left: 0;
      top: 9px;
      width: 14px;
      height: 14px;
      background: $chat no-repeat 0 0;
      background-size: cover;
    }

    &:hover {
      &:before {
        background-image: $chat-blue;
      }
    }
  }
}

@media #{$mobile} {
  .header {
    padding-top: 21px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border;
  }

  .header__logo {
    width: 160px;
  }

  .header__menu {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    padding-right: 20px;
    border-bottom: 1px solid $border;
    opacity: 0;
    background: #fff;
    transition: opacity $long-transition;

    &.--opened {
      visibility: visible;
      left: -$section-padding-mobile;
      right: -$section-padding-mobile;
      top: 69px;
      opacity: 1;
      z-index: 11;
      padding: 11px 12px 18px;
    }

    &.--desktop {
      display: none;
    }

    &.--mobile {
      display: block;
    }
  }

  .header__menu__item {
    display: block;
    margin-bottom: 20px;
    margin-left: 0;
    padding-left: $section-padding-mobile;
    padding-right: $section-padding-mobile;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }

  .header__menu-btn {
    position: relative;
    left: 5px;
    width: 23px;
    height: 23px;

    &:before, &:after, &__i {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background: $primary;
      transform: rotate(0deg);
      transition: transform $short-transition;
    }

    &:before {
      top: 5px;
    }

    &:after {
      bottom: 0;
    }

    &__i {
      top: 13px;

      .--opened & {
        background: transparent;
      }
    }

    &.--opened {
      z-index: 12;

      &:before {
        top: 11px;
        transform: rotate(-45deg);
      }

      &:after {
        bottom: 10px;
        transform: rotate(45deg);
      }
    }
  }
}

