.footer__i {
  @include row;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid $border;
}

.footer__descr {
  margin-bottom: 1px;
  font-size: 16px;
  line-height: 22px;
}

.footer__copy {
  font-size: 14px;
  line-height: 26px;
  color: #95a5a6;
}

.footer__link {
  a {
    font-size: 14px;
    line-height: 22px;
    text-decoration: underline;
  }
}

.footer__payments {
  margin-top: 16px;
}

.footer__text {
  flex: 0 0 310px;
}

.footer__title {
  margin-bottom: 2px;

  svg {
    width: 136px;
    height: auto;
  }
}

.footer__descr {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 22px;
}

.footer__sub-title {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #95a5a6;
}

@media #{$tablet-mobile} {
  .footer {
    &.--with-padding {
      padding-bottom: 30px;
    }
  }

  .footer__text {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .footer__descr {
    width: 50%;
  }
}

@media #{$mobile} {
  .footer__descr {
    width: 100%;
  }

  .footer__checkout, .footer__contact, .footer__legal {
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
}
