// MediaQueries
$tiny-mobile: 'screen and (max-width: 374px)';
$mobile: 'screen and (max-width: 767px)';
$tablet-mobile: 'screen and (max-width: 1023px)';
$tablet-only: 'screen and (min-width: 768px) and (max-width: 1023px)';
$tablet: 'screen and (min-width: 768px)';
$desktop: 'screen and (min-width: 1024px)';
$desktop-normal: 'screen and (min-width: 1280px)';
$desktop-wide: 'screen and (min-width: 1500px)';
$desktop-high: 'screen and (min-height: 900px)';
$retina-display:
(-webkit-min-device-pixel-ratio: 2) and ($tablet),
(min--moz-device-pixel-ratio: 2) and ($tablet),
(-o-min-device-pixel-ratio: 2) and ($tablet),
(min-device-pixel-ratio: 2) and ($tablet),
(min-resolution: 2dppx) and ($tablet);

// Transition speed
$short-transition: 0.15s;
$medium-transition: 0.25s;
$long-transition: 0.35s;

// Input types
$input-types: ("[type='text']", "[type='password']", "[type='email']", "[type='tel']", "[type='number']", "[type='date']", textarea, select);
$input-selectors: ();
$input-focused-selectors: ();
$input-disabled-selectors: ();

@each $type in $input-types {
  $input-selectors: append($input-selectors, $type, 'comma');
}

@each $type in $input-types {
  $input-focused-selectors: append($input-focused-selectors, unquote('#{$type}:focus'), 'comma');
}

@each $type in $input-types {
  $input-disabled-selectors: append($input-disabled-selectors, unquote('#{$type}:disabled'), 'comma');
}

// Icons
$chat: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4gICAgPHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMiAwSDIuOEEyLjggMi44IDAgMCAwIDAgMi44VjE0bDQuODI0LTMuNUgxMS4yQTIuOCAyLjggMCAwIDAgMTQgNy43VjIuOEEyLjggMi44IDAgMCAwIDExLjIgMHoiLz48L3N2Zz4=);
$chat-blue: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4gICAgPHBhdGggZmlsbD0iIzM0OThEQiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOC44IDBIMi4yQTIuMiAyLjIgMCAwIDAgMCAyLjJWMTFsMy43OS0yLjc1SDguOGEyLjIgMi4yIDAgMCAwIDIuMi0yLjJWMi4yQTIuMiAyLjIgMCAwIDAgOC44IDB6Ii8+PC9zdmc+);
$check-small: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE2IDExIj4gICAgPHBhdGggZmlsbD0iIzM0OThEQiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMzNDk4REIiIGQ9Ik02LjI2IDEwYS41ODcuNTg3IDAgMCAxLS40MDgtLjE2M0wxLjE2OSA1LjMxNGEuNTQzLjU0MyAwIDAgMSAwLS43ODcuNTkuNTkgMCAwIDEgLjgxNSAwbDQuMjc1IDQuMTMgNy43NTctNy40OTRhLjU5LjU5IDAgMCAxIC44MTUgMCAuNTQzLjU0MyAwIDAgMSAwIC43ODdMNi42NjcgOS44MzdhLjU4NS41ODUgMCAwIDEtLjQwOC4xNjN6Ii8+PC9zdmc+);
$check: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTQgOSI+ICAgIDxwYXRoIGZpbGw9IiMzNDk4REIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTUuMjYgOWEuNTg3LjU4NyAwIDAgMS0uNDA4LS4xNjNMLjE2OSA0LjMxNGEuNTQzLjU0MyAwIDAgMSAwLS43ODcuNTkuNTkgMCAwIDEgLjgxNSAwbDQuMjc1IDQuMTNMMTMuMDE2LjE2MmEuNTkuNTkgMCAwIDEgLjgxNSAwIC41NDMuNTQzIDAgMCAxIDAgLjc4N0w1LjY2NyA4LjgzN0EuNTg1LjU4NSAwIDAgMSA1LjI1OSA5eiIvPjwvc3ZnPg==);
$check-product: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE1IDExIj4gICAgPHBhdGggZmlsbD0iIzM0OThEQiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMzNDk4REIiIGQ9Ik02LjA3MSAxMGEuNTU2LjU1NiAwIDAgMS0uMzkyLS4xNjNMMS4xNjMgNS4zMTRhLjU1Ny41NTcgMCAwIDEgLjc4Ni0uNzg3bDQuMTIyIDQuMTMgNy40OC03LjQ5NGEuNTU3LjU1NyAwIDAgMSAuNzg2Ljc4N0w2LjQ2NCA5LjgzN2EuNTUzLjU1MyAwIDAgMS0uMzkzLjE2M3oiLz48L3N2Zz4=);
$mail: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIj4gICAgPHBhdGggZmlsbD0iIzk1QTVBNiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTMuMjMgMEguNzdBLjc3Mi43NzIgMCAwIDAgMCAuNzcxVjkuMjNjMCAuNDI1LjM0Ni43NzEuNzcuNzcxaDEyLjQ2Yy40MjQgMCAuNzctLjM0Ni43Ny0uNzcxVi43N2MuMDE1LS40NC0uMzMtLjc3MS0uNzctLjc3MXptLTEuNDkzIDEuNTQ0bC00LjczIDMuMjktNC44MjMtMy4yOWg5LjU1M3ptLjcyMyA2Ljg5N0gxLjU0di01LjQ4bDUuMDI3IDMuNDMzYS43MzMuNzMzIDAgMCAwIC40NC4xNDIuNzM3LjczNyAwIDAgMCAuNDQtLjE0Mmw1LjAyOS0zLjQ4VjguNDRoLS4wMTZ6Ii8+PC9zdmc+);

