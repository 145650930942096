.terms {
  padding: 23px 0 40px;
}

.terms__title {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
}

.terms__content {
  max-width: 700px;

  h3 {
    font-size: 30px;
    line-height: 38px;
  }

  h2, h3, h4, h5 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  b, strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 10px;
  }

  li {
    position: relative;
    padding-left: 27px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      top: 11px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: #000;
    }
  }
}

@media #{$tablet} {
  .terms__title {
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 48px;
  }

  .terms__content {
    flex: 0 0 #{$grid-item-size * 8}#{'%'};
    font-size: 18px;
    line-height: 26px;
  }
}

@media #{$desktop} {
  .terms__title {
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 38px;
  }

  .terms__i {
    justify-content: space-around;
  }

  .terms__content {
    flex: 0 0 #{$grid-item-size * 6}#{'%'};
    font-size: 18px;
    line-height: 26px;

    ul {
      margin: 25px 0;
    }
  }
}
