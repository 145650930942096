$section-padding-mobile: 14px;
$section-padding-tablet: 30px;
$section-padding-desktop: 43px;

.page {
  overflow: hidden;
  min-width: 320px;
}

.centered {
  justify-content: space-around;
}

.vertical-aligned {
  align-items: center;
}
